<template>
  <cz-dialog
    v-on="$listeners"
    v-bind="$attrs"
    :width="1300"
    :max-width="'90%'"
    :closeable="!loading"
  >
    <div class="pa-6 pb-2 position-relative">
      <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
        <cz-form-field
          :label="inputLabel || ''"
          :required="!!inputLabel"
          v-if="!orderDocumentFile"
        >
          <validation-provider
            :rules="'required|mimes:application/pdf'"
            :name="inputLabel"
            v-slot="{ errors }"
            ref="validator"
          >
            <v-input :error-messages="errors" :value="orderDocumentFile">
              <v-fade-transition>
                <cz-dropzone
                  :hide-title="!!orderDocumentFile"
                  accept="application/pdf,image/jpeg,image/png,image/bmp"
                  @change="onOrderDocumentChange"
                  width="100%"
                  @blur="$refs.validator.validate()"
                  :title="$t('dropzone.title')"
                  :height="'auto'"
                  :min-height="400"
                  use-file-reader
                  class="py-4"
                  ref="dropzone"
                  style="cursor: pointer"
                >
                  <template #top v-if="!orderDocumentFile">
                    <v-img
                      :src="uploadDoc"
                      :max-height="180"
                      :max-width="200"
                      contain
                      @click="onUploadDocClicked"
                    />
                  </template>
                </cz-dropzone>
              </v-fade-transition>
            </v-input>
          </validation-provider>
        </cz-form-field>
        <div v-else class="d-flex flex-column align-center" style="gap: 15px">
          <v-card :loading="loading" outlined>
            <template #progress>
              <v-progress-linear
                color="buttonSecondary"
                :value="uploadProgress"
                :height="5"
              ></v-progress-linear>
            </template>

            <v-card-text style="position: relative">
              <pdf-viewer
                v-if="orderDocumentFile.type === 'application/pdf'"
                :source="orderDocument.url"
                :style="{
                  opacity: loading ? 0.2 : 1
                }"
              />
              <v-img
                v-else-if="orderDocumentFile.type.startsWith('image/')"
                :src="orderDocument.url"
                contain
                :max-width="550"
              />
              <v-img
                v-if="loading"
                src="@/assets/corz-loading-ai.gif"
                style="position: absolute; top: 32%; left: 0; right: 0"
              />
            </v-card-text>
          </v-card>
          <div class="d-flex justify-center align-center full-width">
            <div class="text-title font-weight-semibold">
              {{ orderDocument.file.name }}
            </div>
            <cz-button
              v-if="!loading"
              :icon-src="mdiCloseCircleOutline"
              color="negative"
              @click="orderDocument = null"
              text
              noPaddings
            />
          </div>
          <div class="d-flex justify-center">
            <v-scale-transition mode="in-out">
              <cz-button
                color="info"
                type="submit"
                :disabled="invalid"
                class="mt-6 px-10"
                :text="loading"
                x-large
                @click="onContinue"
              >
                <div
                  class="d-flex justify-space-between full-width"
                  style="column-gap: 20px"
                  v-if="loading"
                >
                  <span class="flex-grow-1">{{ loadingTitle }}</span>
                </div>
                <div
                  v-else
                  class="flex-grow-1 d-flex align-center justify-space-between"
                >
                  <cz-icon :src="mdiMagicStaff" class="ml-3" />
                  <span>{{ $t('common.continue') }}</span>
                </div>
              </cz-button>
            </v-scale-transition>
          </div>
        </div>
      </validation-observer>
    </div>
  </cz-dialog>
</template>

<script>
import {
  CzDialog,
  CzDropzone,
  CzFormField,
  CzChip,
  CzButton,
  CzIcon,
  CzSpinner
} from '@/components';
import {
  mdiInformation,
  mdiDeleteOutline,
  mdiCloseCircleOutline,
  mdiMagicStaff
} from '@mdi/js';
import PdfViewer from 'vue-pdf-embed/dist/vue2-pdf-embed';
import uploadDoc from '@/assets/illustrations/upload-document.svg';
export default {
  name: 'NewOrderDialog',
  components: {
    CzDialog,
    PdfViewer,
    CzDropzone,
    CzButton,
    CzFormField,
    CzChip,
    CzSpinner,
    CzIcon
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    loadingTitle: {
      type: String
    },
    inputLabel: {
      type: String,
      required: true
    },
    uploadProgress: {
      type: Number,
      default: 0
    }
  },
  computed: {
    orderDocumentFile() {
      return this.orderDocument?.file;
    }
  },
  data() {
    return {
      uploadDoc,
      mdiCloseCircleOutline,
      mdiMagicStaff,
      mdiInformation,
      mdiDeleteOutline,
      orderDocument: null,
      autoExtractData: true
    };
  },
  methods: {
    onOrderDocumentChange(files) {
      this.orderDocument = files[0];
    },
    onUploadDocClicked() {
      this.$refs.dropzone.onBrowseFileClicked();
    },
    onContinue() {
      if (this.loading) {
        return;
      }

      this.$emit('continue', this.orderDocument.file);
    }
  }
};
</script>

<style></style>
